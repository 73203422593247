<template>
  <footer
    class="bg-slate-900 min-h-10 w-full mt-8 border-t-2 border-t-white text-white flex justify-center items-center"
  >
    <span
      >Made with ❤️ by
      <a
        target="_blank"
        href="https://resite.link/yaikarace"
        class="text-purple-600 hover:text-white hover:underline"
        >YaikaRace</a
      ></span
    >
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FooterComponent",
});
</script>
