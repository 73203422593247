import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '../assets/logo.png'


const _hoisted_1 = { class: "h-12 w-screen bg-slate-900 sticky top-0 z-[1] text-white border-white border-b-[1px] shadow-xl shadow-slate-900" }
const _hoisted_2 = { class: "w-11/12 max-w-[70rem] mx-auto h-full flex items-center relative" }
const _hoisted_3 = { class: "hidden h-full w-full p-4 md:flex items-center" }
const _hoisted_4 = {
  key: 0,
  class: "ml-auto"
}
const _hoisted_5 = { class: "w-fit h-full md:hidden" }
const _hoisted_6 = { class: "w-6 h-6 relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_user_dropdown = _resolveComponent("user-dropdown")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: { name: 'home' },
        class: "font-bold text-2xl text-center w-9 mr-6 invert",
        "active-class": "logo-active",
        "exact-active-class": "logo-exact-active"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "logo",
            class: "w-full"
          }, null, -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages as any, (page) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              key: page.id,
              class: "navbar-button mr-1",
              to: { name: page.route }
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(page.name), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ]),
        (!_ctx.loggedIn)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.footerButtons as any, (button) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  key: button.id,
                  to: { name: button.route },
                  class: _normalizeClass(["navbar-button ml-1", [
              button.color ? button.color : '',
              button.hover ? button.hover : '',
            ]]),
                  "exact-active-class": 
              button.selected ? button.selected : 'router-link-exact-active'
            
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(button.name), 1)
                  ]),
                  _: 2
                }, 1032, ["to", "exact-active-class", "class"]))
              }), 128))
            ]))
          : (_openBlock(), _createBlock(_component_user_dropdown, {
              key: 1,
              class: "ml-auto"
            }))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openSideMenu && _ctx.openSideMenu(...args)), ["prevent"])),
          type: "button",
          class: "w-fit h-full absolute right-0 z-[100]"
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_font_awesome_icon, {
              icon: "fa-solid fa-bars",
              class: _normalizeClass(["min-w-4 h-6 absolute top-1/2 -translate-y-1/2 -translate-x-1/2 transition-all duration-200", {
                'opacity-0': _ctx.sideMenuVisible,
                'rotate-180': _ctx.sideMenuVisible,
              }])
            }, null, 8, ["class"]),
            _createVNode(_component_font_awesome_icon, {
              icon: "fa-solid fa-xmark",
              class: _normalizeClass(["min-w-4 h-7 absolute top-1/2 -translate-y-1/2 -translate-x-1/2 transition-all duration-200", {
                'opacity-0': !_ctx.sideMenuVisible,
                'rotate-180': !_ctx.sideMenuVisible,
              }])
            }, null, 8, ["class"])
          ])
        ])
      ])
    ])
  ]))
}