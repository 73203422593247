import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-slate-900 min-h-10 w-full mt-8 border-t-2 border-t-white text-white flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("span", null, [
      _createTextVNode("Made with ❤️ by "),
      _createElementVNode("a", {
        target: "_blank",
        href: "https://resite.link/yaikarace",
        class: "text-purple-600 hover:text-white hover:underline"
      }, "YaikaRace")
    ], -1)
  ])))
}