import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dropdown hidden font-bold relative min-w-32 h-fit md:block" }
const _hoisted_2 = { class: "text-center border-2 border-b-0 rounded-t-lg border-transparent w-full" }
const _hoisted_3 = { class: "absolute hidden text-center min-w-full border-2 border-t-0 rounded-b-lg border-transparent" }
const _hoisted_4 = { class: "block md:hidden" }
const _hoisted_5 = { class: "sidebar-button" }
const _hoisted_6 = { class: "text-center min-w-full border-2 border-t-0 border-transparent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.userInfo.username) + " ", 1),
        _createVNode(_component_font_awesome_icon, {
          icon: "fa-solid fa-caret-down",
          class: "ml-1"
        })
      ]),
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'profile' },
            class: "drop-button"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Profile")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'settings' },
            class: "drop-button"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Settings")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: { name: 'logout' },
            class: "drop-button"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Log Out")
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("details", _hoisted_5, [
        _createElementVNode("summary", null, _toDisplayString(_ctx.userInfo.username), 1),
        _createElementVNode("ul", _hoisted_6, [
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, {
              to: { name: 'profile' },
              class: "drop-button py-2"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Profile")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, {
              to: { name: 'settings' },
              class: "drop-button py-2"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Settings")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, {
              to: { name: 'logout' },
              class: "drop-button py-2"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Log Out")
              ])),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}