import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "min-h-full min-w-56 bg-slate-900 fixed right-0 flex flex-col items-center p-4 text-center" }
const _hoisted_2 = { class: "h-full w-full" }
const _hoisted_3 = {
  key: 0,
  class: "min-h-full w-full mt-auto"
}
const _hoisted_4 = {
  key: 1,
  class: "min-h-full w-full mt-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_user_dropdown = _resolveComponent("user-dropdown")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "sidemenu",
    duration: 200
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("aside", {
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sideMenuVisibility && _ctx.sideMenuVisibility(...args)), ["self"])),
        class: "text-white fixed min-h-[100dvh] min-w-full z-[1] top-0 left-0 md:hidden"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "h-8 w-full mb-4 border-white border-b-[1px]" }, null, -1)),
          _createElementVNode("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages as any, (page) => {
              return (_openBlock(), _createElementBlock("li", {
                key: page.id
              }, [
                _createVNode(_component_router_link, {
                  class: "sidebar-button",
                  to: { name: page.route },
                  onClick: _ctx.sideMenuVisibility
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(page.name), 1)
                  ]),
                  _: 2
                }, 1032, ["to", "onClick"])
              ]))
            }), 128))
          ]),
          (!_ctx.loggedIn)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.footerButtons as any, (button) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: button.id
                  }, [
                    _createVNode(_component_router_link, {
                      to: { name: button.route },
                      onClick: _ctx.sideMenuVisibility,
                      class: _normalizeClass([[
                button.color ? button.color : '',
                button.hover ? button.hover : '',
              ], "sidebar-button"]),
                      "exact-active-class": 
                button.selected ? button.selected : 'router-link-exact-active'
              
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(button.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["to", "onClick", "class", "exact-active-class"])
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_user_dropdown)
              ]))
        ])
      ], 512), [
        [_vShow, _ctx.sideMenuVisible]
      ])
    ]),
    _: 1
  }))
}